import API from '@/api';

const getMe = () => {
	return API.get(`/user/me`, {
	});
}

const getToken = () => {
	return API.get('/telegramBot/getToken');
}

export default {
	getMe,
	getToken
};
