import axios from 'axios';
import config from './config';
export const customAxios = axios.create(config(localStorage.getItem('token')));
customAxios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    config.headers.TelegramBotInitData = `${localStorage.getItem('TelegramBotInitData')}`;
    config.headers.TelegramBotChatId = `${localStorage.getItem('TelegramBotChatId')}`;

    return config;
}, null, { synchronous: true });

export default customAxios;
