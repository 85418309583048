import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/Default'),
      name: 'main',
      children: [
        {
          path: '',
          component: () => import('@/views/Balance'),
          meta: { title: 'Balance' }
        },
        {
          path: 'exchange',
          component: () => import('@/views/Exchange'),
          meta: { title: 'Exchange' }
        },
        {
          path: 'invoce',
          component: () => import('@/views/Invoce'),
          meta: { title: 'Invoce' }
        },
        {
          path: 'setting',
          component: () => import('@/views/Setting'),
          meta: { title: 'Setting' }
        },
        {
          path: 'login',
          component: () => import('@/views/Login'),
          meta: { title: 'Login' }
        },
        {
          path: 'register',
          component: () => import('@/views/Register'),
          meta: { title: 'Register' }
        },
        {
          path: 'confirm',
          component: () => import('@/views/Confirm'),
          meta: { title: 'Confirm' }
        },
        {
          path: 'deposit/:hash',
          component: () => import('@/views/Status/DepositStatus'),
          meta: { title: 'Deposit Status' }
        },
        {
          path: 'withdraw/:hash',
          component: () => import('@/views/Status/WithdrawStatus'),
          meta: { title: 'Withdraw Status' }
        },
        {
          path: 'swap/:hash',
          component: () => import('@/views/Status/SwapStatus'),
          meta: { title: 'Swap Status' }
        },
      ]
    },
    // {
    //   path: '*',
    //   redirect: '/'
    // }
  ]
})
