<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
import ApiAccount from "@/api/account";

export default {
  name: "App",
  data() {
    return {};
  },
  async mounted() {
    this.$nextTick(() => {
      window.Telegram.WebApp.expand();
    });

    var url = new URL(location.href);
    var chatId = url.searchParams.get("chatId");

    if (chatId) {
      localStorage.setItem("TelegramBotChatId", chatId);
    }

    localStorage.setItem(
      "TelegramBotInitData",
      window.Telegram.WebApp.initData
    );

    await ApiAccount.getToken().then(
      (responce) => {
        this.$toast.clear();
        if (responce.status == 200) {
          localStorage.setItem("token", responce.data.token);
        } else {
          this.$toast.loading({
            duration: 0,
            message: "Error",
            forbidClick: true,
          });
        }
      },
      () => {
        this.$toast.clear();
        this.$router.push({ path: "/register" });
      }
    );
  },
};
</script>

<style >
</style>
