import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import i18n from '@/i18n'
// import store from './store'
// Vue.use(store);

import { Form as ElForm, Input, FormItem, Avatar } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElForm);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Avatar);

Vue.config.productionTip = false


import {
  Button,
  Toast,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Empty,
  Loading,
  Form,
  Field,
  DropdownMenu,
  DropdownItem,
  NavBar,
  Cell,
  CellGroup,
  ActionSheet,
  Dialog,
  Row,
  Card,
  Notify,
  Divider,

  PasswordInput, NumberKeyboard
} from 'vant';

Vue.use(Divider);
Vue.use(Button);
Vue.use(Toast);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Empty);
Vue.use(Loading);
Vue.use(Form);
Vue.use(Field);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(NavBar);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(ActionSheet);
Vue.use(Dialog);
Vue.use(Row);
Vue.use(Card);
Vue.use(Notify);

Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
// // telegram_web_app
// const telegram_web_app = document.createElement('script')
// telegram_web_app.setAttribute('src', 'https://telegram.org/js/telegram-web-app.js')
// document.body.appendChild(telegram_web_app)

import 'vant/lib/index.css';

import "@/assets/scss/style.css";

new Vue({
  el: "#app",
  router: router,
  i18n,
  render: h => h(App),
})


