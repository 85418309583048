const config = function () {
  return {
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      TelegramBotInitData: localStorage.getItem('TelegramBotInitData'),
      TelegramBotChatId: localStorage.getItem('TelegramBotChatId'),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
};

export default config;
